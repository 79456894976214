<section [ngClass]="{ isHidden: isHidden, isMinSize: isMinSize, isMobile: isMobile}">
  <header
    class="dialog-headline"
    [ngClass]="{'success': (data.status === 'success'), 'error': (data.status === 'error'), 
    'warning': (data.status === 'warning'), 
    'mp': (data.status === 'warning'  && checkIfMPAndNotScanned()),
    'loading': (loading$ | async) }"
  >
    <div>
      <button mat-icon-button class="icon-close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
      <h1>
        {{ (data.status === 'warning' && checkIfMPAndNotScanned()) ? 'Multi package' : (data.status | humanize) }}
      </h1>
    </div>

    <div class="size-btns">
      <button mat-icon-button *ngIf="!isMobile" (click)="toggleMaxSize()">
        <mat-icon [svgIcon]="'maxSize'" [ngClass]="{ 'isHidden': isHidden }"></mat-icon>
      </button>
      <button mat-icon-button (click)="toggleMinSize()">
        <mat-icon [svgIcon]="'minSize'" [ngClass]="{ 'isHidden': isHidden }"></mat-icon>
      </button>
      <button mat-icon-button (click)="toggleVisibility()">
        <mat-icon [svgIcon]="'minimize'" [ngClass]="{ 'isHidden': isHidden }"></mat-icon>
      </button>
    </div>
  </header>

  <div *ngIf="(loading$ | async); else content" class="loader-wrapper">
    <app-loader class="loader--large"></app-loader>
  </div>

  <ng-template #content>
    <h2
      class="title body"
      [ngClass]="{'success': (data.status === 'success'), 'error': (data.status === 'error'), 'warning': (data.status === 'warning'),
      'mp': (data.status === 'warning' &&  checkIfMPAndNotScanned() )}"
    >
      <ng-container *ngIf="data.order && data.order.packages.length > 1 else noMp">
        <span>
          <a [routerLink]="['/app/search/', scannerTrackingNumber || data.order.trackingNumber]"
            >{{scannerTrackingNumber || data.order.trackingNumber}}</a
          >&nbsp;{{data.order.nextArea ? ('Take to ' + data.order.nextArea | humanize) : ''}}
        </span>
      </ng-container>
      <ng-template #noMp>
        <span>
          <a [routerLink]="['/app/search/', scannerTrackingNumber || data.order?.trackingNumber]"
            >{{scannerTrackingNumber || data.order?.trackingNumber}}</a
          >&nbsp;{{data.order?.nextArea ? ('Take to ' + data.order?.nextArea | humanize) : ''}}
        </span>
      </ng-template>
      <span class="mp-count" *ngIf=" data.order && data.order.packages">
        {{ ((isMobile || isMinSize) ? 'MP:' : 'Multi Package:')}}&nbsp; {{ data.order.packages.length }}&nbsp;
        <span *ngIf="!isMobile && !isMinSize">{{ data.order.packages.length === 1 ? 'Package' : 'Packages' }}</span>
        <span *ngIf="isMobile || isMinSize"> PKGs</span>
      </span>
    </h2>

    <ng-container *ngIf="data.status === 'error'">
      <div class="failed body">
        <mat-icon class="error-icon" svgIcon="radioactive"></mat-icon>
        <h3 class="head1">{{ data.error ? data.error : data.message }}</h3>
      </div>
    </ng-container>

    <ng-container *ngIf="data.status === 'warning' && !checkIfMPAndNotScanned()">
      <div class="failed warn body">
        <mat-icon class="warning-icon" svgIcon="alertdiamond"></mat-icon>
        <h3 class="head1 warn">{{ data.warning ? data.warning : data.message }}</h3>
      </div>
    </ng-container>

    <ng-container *ngIf="data.status === 'success' || ( data.status === 'warning' && checkIfMPAndNotScanned() )">
      <div class="info-container">
        <div class="info-box">
          <h3>TYPE</h3>
          <p>{{ data.order?.type }}</p>
        </div>
        <div class="info-box">
          <h3>STATUS</h3>
          <p>{{ data.order?.storyStatus }}</p>
        </div>
        <div class="info-box" *ngIf="data.order?.session">
          <h3>COUNTER <span>SCANNED/TOTAL</span></h3>
          <p>{{ data.order?.session?.scanned }} / {{ data.order?.session?.total }}</p>
        </div>
        <div class="info-box">
          <h3>AGING</h3>
          <p>{{ data.order?.created | ageInDays }} days</p>
        </div>
        <div class="info-box">
          <h3>ATTEMPTS</h3>
          <p>{{ (data.order?.activeRoute === 'pickUp') ? data.order?.pickUpAttempts : data.order?.dropOffAttempts }}</p>
        </div>
        <div class="info-box">
          <h3>WEIGHT</h3>
          <p>{{ data.order?.weight }}</p>
        </div>
        <div class="info-box">
          <h3>SHELF</h3>
          <p>{{ data.order?.shelfId || 'N/A' }}</p>
        </div>
        <div class="info-box">
          <h3>ZONE</h3>
          <p>{{ (data.order?.activeRoute === 'pickUp') ? data.order?.pickUpZoneName : data.order?.dropOffZoneName }}</p>
        </div>
        <div class="info-box">
          <h3>CITY</h3>
          <p>{{ data.order?.activeRoute === 'pickUp' ? data.order?.pickUpCity : data.order?.dropOffCity }}</p>
        </div>
        <div class="info-box">
          <h3>COUNTRY</h3>
          <p>{{ data.order?.activeRoute === 'pickUp' ? data.order?.pickUpCountry : data.order?.dropOffCountry }}</p>
        </div>
        <div class="info-box">
          <h3>{{ data.order?.activeRoute | uppercase }} ADDRESS</h3>
          <p>{{ data.order?.activeRoute === 'pickUp' ? data.order?.pickUpAddress : data.order?.dropOffAddress }}</p>
        </div>
        <div class="info-box">
          <h3>CUSTOMER</h3>
          <p>{{ data.order?.customerName }}</p>
        </div>
        <div class="info-box">
          <h3>IS RETURN</h3>
          <p>{{ data.order?.returnRequest ? 'Yes' : 'No' }}</p>
        </div>
        <div class="info-box">
          <h3>CURRENT AREA</h3>
          <p>{{ (data.order?.currentArea | humanize) || 'N/A' }}</p>
        </div>
        <div class="info-box">
          <h3>FAILURE REASON</h3>
          <p>
            <ng-container [ngSwitch]="data.order?.activeRoute">
              <ng-container *ngSwitchCase="'pickUp'">
                {{ data.order?.pickUpFailureReason ? data.order?.pickUpFailureReason : 'N/A' }}
              </ng-container>
              <ng-container *ngSwitchCase="'dropOff'">
                {{ data.order?.dropOffFailureReason ? data.order?.dropOffFailureReason : 'N/A' }}
              </ng-container>
              <ng-container *ngSwitchDefault> N/A </ng-container>
            </ng-container>
          </p>
        </div>
        <div class="info-box">
          <h3>DRIVER NAME</h3>
          <p>{{ data.order?.driverName || 'N/A' }}</p>
        </div>
      </div>

      <!-- <div class="info-container">
        @for (item of $any(data.order) | keyvalue; track item) {
        <div class="info-box" *ngIf="item.value !== null && item.value !== undefined">
          @if (item.key === 'session') {
          <h3>{{$any(item.key) | humanize | uppercase}} COUNTER <span>SCANNED/TOTAL</span></h3>
          <p>{{$any(item.value).scanned}} / {{$any(item.value).total}}</p>
          } @else if (item.key === 'packages') {
          <h3>{{$any(item.key) | humanize | uppercase}} ORDERS COUNTER <span>SCANNED/TOTAL</span></h3>
          <p>{{$any(item.value) | countScanned}} / {{$any(item.value).length}}</p>
          } @else {
          <h3>{{ $any(item.key) | humanize | uppercase }}</h3>
          @if ((item.value | typeof) === 'number') { @if (item.value.toString().length === 13) {
          <p>{{ $any(item.value) | date: 'dd/MM/yyyy HH:mm' }}</p>
          } @else {
          <p>{{ item.value }}</p>
          } } @else {
          <p>{{ item.value }}</p>
          } }
        </div>
        }
      </div> -->

      <!-- <mat-grid-list class="grid-list" [cols]="firstGridBreakpoint" rowHeight="fit">
        <mat-grid-tile>
          <div class="info">
            <h3 *ngIf="data.manifestId || (manifestDetails$ | async)?.id">Manifest</h3>
            <p *ngIf="data.manifestId || (manifestDetails$ | async)?.id">
              {{ data.manifestId? data.manifestId: (manifestDetails$| async)?.id }}
            </p>

            <h3 *ngIf="!data.manifestId && !(manifestDetails$ | async)?.id">Shipment</h3>
            <p *ngIf="!data.manifestId && !(manifestDetails$ | async)?.id">{{ data.order?.trackingNumber }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Type</h3>
            <p>{{ data.order?.category || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Status</h3>
            <p>{{(data.order?.storyStatus | humanize) || (data.status | humanize) || 'N/A'}}</p>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list class="grid-list gray" [cols]="secondGridBreakpoint" rowHeight="fit">
        <mat-grid-tile>
          <div class="info">
            <h3>Aging</h3>
            <p>{{ getAgeInDays(data.order?.created) }} days</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Attempts</h3>
            <p>N/A</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Weight</h3>
            <p>{{ data.order?.weight || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Counter</h3>
            <p>{{ (data.order?.packages | countScanned) }} / {{data.order?.packages?.length}}</p>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list class="grid-list" [cols]="thirdGridBreakpoint" rowHeight="fit">
        <mat-grid-tile [colspan]="columnSpan">
          <div class="info">
            <h3>Hub</h3>
            <p>{{ data.order?.dropOffZoneName || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="info">
            <h3>Zone</h3>
            <p>{{ data.order?.dropOffZoneName || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="info">
            <div>
              <h3>Shelf</h3>
              <p>{{ data.order?.shelfId || 'N/A' }}</p>
            </div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="info">
            <h3>City</h3>
            <p>{{ data.order?.dropOffCity || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="info">
            <h3>Country</h3>
            <p>{{ data.order?.dropOffCountry || 'N/A' }}</p>
          </div>
        </mat-grid-tile>
      </mat-grid-list> -->

      <!-- <div class="buttons-wrapper" *ngIf="!this.isScanIn && checkIfMPAndNotScanned()"> -->
      <div class="buttons-wrapper" *ngIf="checkIfMPAndNotScanned()">
        <button mat-button class="reset-btn" (click)="close()">Validate later</button>
        <button mat-flat-button color="accent" class="submit-btn" (click)="validateMp()">Validate now</button>
      </div>
    </ng-container>
  </ng-template>
</section>
