import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMenuChildRoles',
})
export class FilterMenuChildRolesPipe implements PipeTransform {
  transform(
    value: { text: string; link: string; role: string }[],
    userPermissions: string[]
  ): { text: string; link: string; role: string }[] {
    return value.filter((subMenu: { role: string }) => {
      return userPermissions.includes(subMenu.role);
    });
  }
}
