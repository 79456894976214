import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageInDays',
})
export class AgeInDaysPipe implements PipeTransform {
  transform(created: number | undefined): number | string {
    if (!created) {
      return 'N/A';
    }
    const todayDate = new Date();
    const createdDate = new Date(created);
    const difference = todayDate.getTime() - createdDate.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  }
}
