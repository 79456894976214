import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@auth/store';
import { selectAuthUserProfile } from '@auth/store/selectors/profile.selectors';
import { Logout } from '@auth/store/actions/auth.actions';
import { UserProfile } from '@auth/interfaces/user-profile.interface';
import { filter, Observable } from 'rxjs';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { SideBarComponents } from '@shared/types/side-bar-components.type';
import { SidebarLoaderService } from '@shared/services/sidebar-loader.service';
import {
  selectHasAdministrationPermit,
  selectHasDashboardsPermit,
  selectHasOperationsPermit,
} from '@general-data/store/selectors/permissions.selectors';
import { MobileMenuToggleService } from '@shared/services/mobile-menu-toggle.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'user-menu' },
    },
  ],
})
export class HeaderComponent {
  public readonly userProfile$: Observable<UserProfile | null>;
  public readonly hasAdminRoles$: Observable<boolean>;
  public readonly hasOperationsRoles$: Observable<boolean>;
  public readonly hasDashboardsRoles$: Observable<boolean>;
  public currentModule: { id: string; text: string; icon: string; link: string };
  public readonly dashboardsModule: { id: string; text: string; icon: string; link: string } = {
    id: 'dashboards',
    text: 'Dashboards',
    icon: 'dashboards',
    link: '/app/reporting',
  };

  public readonly operationsModule: { id: string; text: string; icon: string; link: string } = {
    id: 'operations',
    text: 'Operations',
    icon: 'operations',
    link: '/app/operations',
  };

  public readonly sysAdminModule: { id: string; text: string; icon: string; link: string } = {
    id: 'systemAdministration',
    text: 'System administration',
    icon: 'settings-slider',
    link: '/app/administration',
  };

  public readonly lobby: { id: string; text: string; icon: string; link: string } = {
    id: 'lobby',
    text: 'Lobby',
    icon: 'lobby',
    link: '/app/home',
  };

  private menuMobileToggle = false;

  constructor(
    private store: Store<State>,
    private sidebarLoaderService: SidebarLoaderService,
    private mobileMenuToggleService: MobileMenuToggleService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.userProfile$ = this.store.select(selectAuthUserProfile);
    this.hasAdminRoles$ = this.store.select(selectHasAdministrationPermit);
    this.hasOperationsRoles$ = this.store.select(selectHasOperationsPermit);
    this.hasDashboardsRoles$ = this.store.select(selectHasDashboardsPermit);
    this.currentModule = this.lobby;

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event) => {
      if (event.url.includes('administration')) {
        this.currentModule = this.sysAdminModule;
      } else if (event.url.includes('operations')) {
        this.currentModule = this.operationsModule;
      } else if (event.url.includes('reporting')) {
        this.currentModule = this.dashboardsModule;
      } else if (event.url.includes('home')) {
        this.currentModule = this.lobby;
      }
      this.cdr.detectChanges();
    });
  }

  public openAccountDetails(): void {
    console.log('openAccountDetails');
    // TODO: Navigate to Account details
  }

  public logout(): void {
    this.store.dispatch(Logout());
  }

  public switchHub(): void {
    this.openSideNav('SwitchHubSidebarComponent');
  }

  public onToggleMenu(): void {
    this.menuMobileToggle = !this.menuMobileToggle;
    this.mobileMenuToggleService.mobileMenuToggle$.next(this.menuMobileToggle);
  }

  private openSideNav(sidebarComp: SideBarComponents): void {
    this.sidebarLoaderService.loadSideBarComp(sidebarComp);
  }
}
