import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ColumnDef } from '@table/interfaces/column-definition.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable, of } from 'rxjs';
import { UsersFilters } from '@administration/features/users-management/interfaces/users-filters.interface';
import { ManifestFilters } from '@operations/features/picking/interfaces/manifest-filters.interface';
import { ReceivingManifestsFilters } from '@operations/features/receiving/interfaces/receiving-filters.interface';
import { MatMenuTrigger, MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { DepartmentsFilters } from '@administration/features/users-management/interfaces/departments-filters.interface';
import { UserGroupsFilters } from '@administration/features/users-management/interfaces/user-groups-filters.interface';
import { ActionRequiredFilters } from '@operations/features/cage/interfaces/action-required-filters.interface';
import { DatePipe, LowerCasePipe } from '@angular/common';
import { HumanizePipe } from '@shared/pipes/humanize.pipe';

@Component({
  selector: 'app-filters-settings',
  templateUrl: './filters-settings.component.html',
  styleUrls: ['./filters-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'overlay-mobile-menu' },
    },
  ],
})
export class FiltersSettingsComponent {
  @Input() columns!: ColumnDef[];
  @Input() appliedFilters: Observable<Partial<
    | UsersFilters
    | ManifestFilters
    | ReceivingManifestsFilters
    | DepartmentsFilters
    | UserGroupsFilters
    | ActionRequiredFilters
  > | null> = of(null);

  @Output() columnsChanged: EventEmitter<ColumnDef[]> = new EventEmitter();
  @Output() openFilters: EventEmitter<void> = new EventEmitter();

  @ViewChild(MatMenuTrigger) columnOpts!: MatMenuTrigger;

  public Object = Object;

  constructor(
    private datePipe: DatePipe,
    private humanizePipe: HumanizePipe,
    private lowerCasePipe: LowerCasePipe
  ) {}

  public onOpenFilters(): void {
    this.openFilters.emit();
  }

  public onDropColumn(event: CdkDragDrop<ColumnDef[]>): void {
    // console.log('event', event);
    if (event.currentIndex !== this.columns.length - 1) {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.columnsChanged.emit(this.columns);
    }
  }

  public onViewColChange(ev: MatSlideToggleChange, colField: any): void {
    this.columns = this.columns.map((colDef: ColumnDef) => {
      if (colDef.field === colField) {
        colDef.hide = !ev.checked;
      }
      return colDef;
    });
    this.columnsChanged.emit(this.columns);
  }

  public checkTypeOfValue(val: any, key: string): string {
    if (Array.isArray(val) && val.length > 1) {
      const itemName = this.humanizePipe.transform(key);
      // change plural for words ending in 'S' except 'numbers' (ex: tracking numbers)
      if (itemName.slice(-1) === 's' && itemName.slice(-7) !== 'Numbers') {
        // console.log('itemName', itemName);
        return `${val.length} ${this.lowerCasePipe.transform(itemName)}es`;
      }
      return `${val.length} ${this.lowerCasePipe.transform(itemName)}'s`;
    } else if (Array.isArray(val)) {
      if (val.length === 0) {
        return 'None';
      }
      return typeof val[0] === 'object' ? val.map((v: any) => v.name).join(', ') : val.toString();
    } else if (val instanceof Date) {
      return this.datePipe.transform(val, 'dd/MM/YYYY') || '';
    } else if (val && typeof val === 'object') {
      return val.name;
    } else if (typeof val === 'string') {
      if (val === val.toUpperCase()) {
        return val;
      }
      return this.humanizePipe.transform(val);
    } else {
      return val;
    }
  }

  public closeMenu(): void {
    this.columnOpts.closeMenu();
  }
}
