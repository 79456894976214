import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMenuParentRoles',
})
export class FilterMenuParentRolesPipe implements PipeTransform {
  transform(
    value: { icon: string; text: string; role: string; subMenu: { text: string; link: string; role: string }[] }[],
    userPermissions: string[]
  ): { icon: string; text: string; role: string; subMenu: { text: string; link: string; role: string }[] }[] {
    const parentRoles = userPermissions.map((permit) => permit.split('.')[0]);
    return value.filter((menu: { role: string }) => {
      if (menu.role) {
        return parentRoles.includes(menu.role);
      }
      return true;
    });
  }
}
